<template>
  <div class="order_page">
    <div style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      ">
      <div class="page_title">{{ language.Running_Bill }}</div>
      <div style="margin-top: 20px">
        <div style="display: flex; flex-wrap: wrap">
          <!-- 变更类型 -->
          <div class="input_box input_box2">
            <el-input v-model="form.memo" :placeholder="language.Change_Operation"></el-input>
          </div>
          <!-- 关联平台订单号 -->
          <div class="input_box input_box2">
            <el-input v-model="form.orderNo" :placeholder="language.Platform_Order_No"></el-input>
          </div>
          <!-- 变更金额 -->
          <div class="input_box input_box2">
            <el-input v-model="form.money" :placeholder="language.money"></el-input>
          </div>
          <!-- 事件类型 -->
          <div class="input_box input_box2">
            <el-select v-model="form.changeType" :placeholder="language.Event_Type">
              <el-option v-for="item in changeTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 交易时间 -->
          <div class="input_box">
            <el-date-picker v-model="form.searchTime" type="daterange" :range-separator="language.to"
              :start-placeholder="language.Start_Date" :end-placeholder="language.End_Date"
              :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd H:mm:ss">
            </el-date-picker>
          </div>

          <!-- 查询 -->
          <div class="input_box">
            <el-button type="primary" id="SearchBtn" @click="SearchBtn()">{{
              language.Search
            }}</el-button>
          </div>
          <!-- 重置 -->
          <div class="input_box">
            <el-button type="danger" id="ResetBtn" @click="ResetBtn()">{{
              language.Reset
            }}</el-button>
          </div>
          <!-- 打印 -->
          <div class="input_box Export_box" :style="Exporttype == 2 ? 'min-width:200px' : ''">
            <el-button v-if="Exporttype == 1" type="success" @click="ExportBnt()">{{
              language.Export
            }}</el-button>
            <el-progress v-if="Exporttype == 2" style="width: 100%" :percentage="percentage"
              :show-text="false"></el-progress>
            <download-excel v-if="Exporttype == 3" class="export-excel-wrapper" :data="json_table" :fields="json_fields"
              type="txt" :name="ordername + '.xls'">
              <el-button type="success">{{ language.Download_Bill }}</el-button>
            </download-excel>
          </div>
        </div>
      </div>
      <div>
        <!-- <div style="padding: 10px 10px 10px 0">
          <div style="
              width: 26px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #2c3e50;
              cursor: pointer;
            " @click="init()">
            <img style="width: 16px" src="../../static/Refresh.png" alt="" />
          </div>
        </div> -->
        <el-table :data="tableData" border style="width: 100%" stripe v-loading="loading"
          :header-cell-style="{ background: '#fafafa' }">
          <!-- 事件类型 -->
          <el-table-column prop="change_type" :label="language.Event_Type" min-width="10%">
            <template slot-scope="scope">
              <div v-for="item in changeTypeList" :key="item.value">
                <span v-if="item.value == scope.row.change_type">{{ item.label }}</span>
              </div>
            </template>
          </el-table-column>
          <!-- 变更类型 -->
          <el-table-column prop="memo" :label="language.Change_Operation" min-width="10%">
          </el-table-column>
          <!-- 关联订单号 -->
          <el-table-column prop="order_no" :label="language.Platform_Order_No" min-width="10%">
          </el-table-column>
          <!-- 变更金额 -->
          <el-table-column :label="language.Amount_Of_Change" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.money }} </span>
              <span style="font-size: 10px">{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 变更前可用金额 -->
          <el-table-column :label="language.Available_Amount_Before_Change" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.before }} </span>
              <span style="font-size: 10px">{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 变更后可用金额 -->
          <el-table-column :label="language.Available_Amount_After_Change" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.after }} </span>
              <span style="font-size: 10px">{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 变更总金额 -->
          <el-table-column :label="language.Total_Amount_Of_Change" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.z_money }} </span>
              <span style="font-size: 10px">{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 变更前总金额 -->
          <el-table-column :label="language.Total_Amount_Before_Change" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.z_before }} </span>
              <span style="font-size: 10px">{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 变更后总金额 -->
          <el-table-column :label="language.Total_Amount_After_Change" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.z_after }} </span>
              <span style="font-size: 10px">{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 备注 -->
          <el-table-column prop="remark" :label="language.remarks" min-width="10%">
          </el-table-column>
          <!-- 时间 -->
          <el-table-column prop="create_time" :label="language.Creation_time" min-width="10%">
          </el-table-column>

        </el-table>
      </div>
      <div>
        <div class="paging">
          <el-pagination v-loading="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :pager-count="5" :page-sizes="[10, 15, 20, 25, 30]" :page-size="limit"
            layout="total, sizes, prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderFlow } from "../../api/index.js";
export default {
  data() {
    return {
      languagetype: "",
      language: "",
      changeTypeList: [
        {
          value: "-1",
          label: "全部", //全部
        },
        {
          value: "1",
          label: "上分",
        },
        {
          value: "2",
          label: "下分",
        },
        {
          value: "3",
          label: "提U",
        },
        {
          value: "4",
          label: "代收",
        },
        {
          value: "5",
          label: "代付",
        },
        {
          value: "6",
          label: "结算",
        },
      ],
      tableData: [],
      json_fields: {
        // "名称": "name",//常规字段
        // "Telephone": "phone.mobile", //支持嵌套属性
      },
      json_table: [],
      form: {
        memo: null,
        orderNo: null,
        money: null,
        changeType: null,
        searchTime: [],
        datetime: null
      },

      total: 1,
      page: 1,
      limit: 15,

      loading: false,
      Exporttype: 1,
      Exportpage: 0, //导出页数
      Exportpageing: 0, //已导出的页数
      Exporttrue: false,
      percentage: 0, //导出百分百
      ordername: "",
      // userinfo: "",
    };
  },
  created() {
    // this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.changelanguage();
    this.init();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    Exporttrue() {
      if (this.Exporttrue) {
        if (this.Exportpageing < this.Exportpage) {
          this.Exportpageing = this.Exportpageing + 1;
          this.Exporttrue = false;
          this.forExport();
        }
      }
    },
  },

  methods: {
    // 获取数据
    init() {
      this.tableData = [];
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
        ...this.form,
        datetime: this.form.searchTime ? this.form.searchTime.join(" - ") : ""
      };
      orderFlow(data, {}).then((res) => {
        if (res.code == 1) {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.count;
        }
      });
    },
    //搜索
    SearchBtn() {
      document.getElementById("SearchBtn").blur();
      this.Exporttype = 1;
      this.page = 1;
      this.total = 1;
      this.init();
    },
    //重置
    ResetBtn() {
      document.getElementById("ResetBtn").blur();
      this.page = 1;
      this.total = 1;
      this.Exporttype = 1;
      this.form.memo = null;
      this.form.orderNo = null
      this.form.money = null;
      this.form.searchTime = []
      this.form.datetime = null;

      this.init();
    },
    // 切换每页条数
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },


    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
      this.changeTypeList[0].label = this.language.whole; //全部
      this.changeTypeList[1].label = this.language.Recharge; //上分
      this.changeTypeList[2].label = this.language.Withdrawal; //下分
      this.changeTypeList[3].label = this.language.Withdraw_U; //提U
      this.changeTypeList[4].label = this.language.Inward_Collection; //代收
      this.changeTypeList[5].label = this.language.Behalf_Payment; //代付
      this.changeTypeList[6].label = this.language.settlement; //结算


      this.json_fields = {
        [this.language.Event_Type]: {
          field: "change_type",
          callback: (value) => {
            let val = ""
            this.changeTypeList.map(item => {
              if (value == item.value) {
                val = item.label
              }
            })
            return val;
          },
        },
        [this.language.Platform_Order_No]: {
          field: "order_no",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.Change_Operation]: "memo",
        [this.language.Amount_Of_Change]: "money",
        [this.language.Available_Amount_Before_Change]: "before",
        [this.language.Available_Amount_After_Change]: "after",
        [this.language.Total_Amount_Of_Change]: "z_money",
        [this.language.Total_Amount_Before_Change]: "z_before",
        [this.language.Total_Amount_After_Change]: "z_after",
        [this.language.remarks]: "remark",
        [this.language.Creation_time]: "create_time",
      };
    },
    // 导出列表
    ExportBnt() {
      this.$message({
        message: this.language.Exportingpleasedonotjumptothepageorotheroperations,
        type: "warning",
      });
      this.Exportpageing = 1;
      this.Exporttype = 2;
      this.json_table = [];
      this.forExport();
    },
    // 循环导出
    forExport() {
      let data;
      data = {
        page: this.Exportpageing,
        limit: 10000,
        ...this.form,
        datetime: this.form.searchTime ? this.form.searchTime.join(" - ") : ""
      };
      this.Exporttrue = false;
      orderFlow(data, {}).then((res) => {
        if (res.code == 1) {
          if (res.data.count == 0) {
            this.Exporttype = 1;
            this.$message({
              message: this.language.Nodata,
              type: "warning",
            });
            return;
          }
          this.Exportpage = Math.ceil(res.data.count / 10000);
          this.Exporttrue = true;
          this.percentage = (this.Exportpageing / this.Exportpage) * 10000;
          //
          this.json_table = [...this.json_table, ...res.data.list];

          if (this.Exportpage == this.Exportpageing) {
            this.ordername = "Order" + this.$getNowFormatDate.getNowtime(new Date());
            this.$nextTick(() => {
              this.Exporttype = 3;
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.order_page>div {
  min-height: calc(100vh - 129px);
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.input_box {
  margin-right: 30px;
  margin-top: 10px;
}

.input_box2 {
  width: 200px;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.search_last_box {
  margin-top: 10px;
  display: flex;
  /* padding: 10px 10px 0 10px; */
  box-sizing: border-box;
  background-color: rgba(242, 242, 242, 1);
}

.search_last_box>div {
  /* width: 300px; */
  /* background-color: rgba(242, 242, 242, 1); */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.search_last_box>div:last-child {
  margin-left: 20px;
}

.search_last_box>div>div:first-child {
  font-family: cursive;
  font-weight: 700;
  color: #333333;
}

.search_last_box>div>div:last-child {
  /* margin-top: 6px; */
  font-weight: 700;
  font-family: cursive;
  margin-left: 10px;
  color: #ff5722;
}

.msgbox {
  display: flex;
  align-items: center;
}

.msgbox:not(:first-child) {
  margin-top: 20px;
}

* /deep/ .el-table .cell {
  white-space: nowrap;
}

.Export_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
